import React from 'react'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Quote } from '../../components/Quote'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import { CallToAction, GetStarted } from '../../components/CallToAction'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';

import SfOnLaptop from '../images/sfOnLaptop.png'
import SfNotes from '../images/sfNotes.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'
import OldWay from '../images/oldWay.svg';
import ActionEvent from '../images/actionEvent.svg';
import SalesforceDashboard from '../images/salesforceDashboard.png'

const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Better Sales Coaching At Scale</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='headsetPerson2'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            Automate Your Sales Training & Coaching
          </PageTitle2>
          <PageTitle as="p" color={colors.white} mb="1.5rem" center>
            Capture, Analyze and Take Action On Every Customer Conversation
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>



    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={OldWay} alt="old way of doing sales"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          STEP 1: ACHIEVE DATA CONFIDENCE
          </Title3>
        <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
         Sales leaders often have low confidence in their CRM  data, which means every coaching/deal review needs to be supplemented with hours of additional discovery (rep anecdotes, checking multiple systems, etc.)
         <br/><br/>
           This means less feedback over longer feedback cycles, with lower deal coverage.  This leads to lower ramp times, lower conversion rates and lost revenue.
        <br/><br/>
           Truly is the only activity tracking platform built from the ground up to give managers full confidence in their CRM data, by ensuring 100% of all client communication is synced, reportable and discoverable in Salesforce.
        </Large>
        </Box>
      </Section>
    </WrapperSolid>



    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
          STEP 2: QUANTIFY EVERYTHING
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
           Once you have all the data in one place,  every aspect of the rep's sales execution can then be quantified into metrics using sales math and AI.  
           <br/><br/>
           These metrics then help you identify whether an issue exists, and if it's a skill problem (talks too much) or a will problem (sandbagging).  
          <br/><br/>
           Truly platform offers the most advanced suite of sales execution metrics in the market 'out of the box', while also making it possible to create your own without writing any code.
          </Large>
        </Box>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={SalesforceDashboard} alt="Quantify all sales data with AI"/>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={ActionEvent} alt="Autoamte revenue process in salesforce"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          STEP 3: DETECT, REPORT AND AUTOMATE
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Now that you have custom reporting metrics you can trust, you can start automating your sales coaching activities.
            <br/><br/>
            Automatically update activity scorecards, flag calls for manager review, build playlists of cold calls/demos that convert and even create automated tasks for reps for follow up.  
            updating an activity scorecard, flagging a manager review, sending the rep a note, or creating a follow up task for the rep. 
            <br/><br/>
            Truly lets you do all of this on 100% of your calls and deals without you having to lift a finger, and lets you build reports in Salesforce that show the net improvements resulting from your automated coaching programs.

          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperBackground background="headsetPerson1" gradientType="purple">
      <CallToAction/>
    </WrapperBackground>

    <WrapperBackground background="sf" gradientType="black">
      <GetStarted/>
    </WrapperBackground>

  </Default>
)

export default SolutionsPage

